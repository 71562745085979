import PerfectScrollbar from 'react-perfect-scrollbar'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const Scrollbar = ({ children }: Props) => {
  return (
    <PerfectScrollbar className='h-full w-full'>
      <div className='mr-4 px-4 pb-4'>
        {children}
      </div>
    </PerfectScrollbar>
  )
}

export default Scrollbar