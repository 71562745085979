import { useRef } from 'react'
import PageSelection from './PageSelection'
import useResultStore from '../../store/resultStore'

type Props = {
  entriesCount: number
  showSelectAmount?: boolean
}

const Pagination = ({ entriesCount, showSelectAmount = true }: Props) => {
  const { entriesPerPage, setEntriesPerPage, page } = useResultStore()
  const selectRef = useRef<HTMLSelectElement>()

  const pageTill = ((page * entriesPerPage) < entriesCount) ? page * entriesPerPage : entriesCount
  const pageFrom = pageTill === 0 ? 0 : (page - 1) * entriesPerPage + 1
  const pageCount = Math.ceil(entriesCount / entriesPerPage)

  if (pageCount < 2) return null

  const onChange = () => setEntriesPerPage(parseInt(selectRef.current.value) as 10 | 25 | 50 | 100)

  return (
    <div className='my-4 mt-6 grid grid-cols-3 gap-4'>
      <div className='flex items-center whitespace-nowrap'>
        <div>Showing <strong>{pageFrom}</strong> to <strong>{pageTill}</strong> of <strong>{entriesCount}</strong> results.</div>
      </div>
      <div className='flex place-content-center items-center text-center text-sm font-medium'>
        <PageSelection pageCount={pageCount} />
      </div>
      {showSelectAmount && (
        <div className='flex place-content-end items-center space-x-4 whitespace-nowrap text-right'>
          <label htmlFor='selectAmount' className='mb-0'>Results:</label>
          <select className='' id='selectAmount' defaultValue={entriesPerPage} ref={selectRef} onChange={onChange}>
            {[ 10, 25, 50, 100 ].map(option => <option key={option} value={option}>{option} Results</option>)}
          </select>
        </div>
      )}
    </div>
  )
}

export default Pagination