import { Button } from 'src/@/components/ui/button'
import Sample from 'src/types/Sample'
import { ColumnDef, SortingFn } from '@tanstack/react-table'
import { ArrowUpDown } from 'lucide-react'
import { Link } from 'react-router-dom'
import Route from 'src/config/route'

const CustomHeaderButton: React.FC<{
  columnName: string
  column: any
  classNameProp: string
}> = ({ columnName, column, classNameProp }) => (
  <Button
    variant='ghost'
    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    className={`${classNameProp} cursor-pointer py-3 pl-4`}
  >
    <span className='text-left'>{columnName}</span>
    <ArrowUpDown className='ml-1 h-4 w-4' />
  </Button>
)

const sortColumnsFn: SortingFn<Sample> = (rowA, rowB, columnId) => {
  const propA = rowA.original[columnId]
  const propB = rowB.original[columnId]

  if (typeof propA === 'number' && typeof propB === 'number') {
    return propA - propB
  }

  return propA.localeCompare(propB)
}

// Write the keys in correct sorting order
const columnNames = [
  'peptide',
  'normalized_intensity',
  'rank',
  'best_predicted_binder',
]

// The column mapping object helps us to define properties for the columns in the table, you can play around with this object for different table types accordingly
const columnMapping: Record<
  string,
  { headerText: string, classNameProp: string }
> = {
  peptide: { headerText: 'Peptide', classNameProp: 'w-80 shrink' },
  normalized_intensity: { headerText: 'Normalized Intensity', classNameProp: 'w-48 shrink' },
  rank: { headerText: 'Rank', classNameProp: 'w-24 shrink' },
  best_predicted_binder: { headerText: 'Best Predicted Binder', classNameProp: 'w-56 shrink' },
}

export const generateColumns = (

  closeDialog
): ColumnDef<Sample>[] => {

  return columnNames.map((columnName) => ({
    accessorKey: columnName,
    header: ({ column }) => (
      <CustomHeaderButton
        columnName={columnMapping[columnName].headerText}
        column={column}
        classNameProp={columnMapping[columnName].classNameProp}
      />
    ),
    // This is the row renderer, where any changes to the row go here
    cell: ({ row }) => {
      const peptideProperty = row.original[columnName]
      if (columnName === 'peptide') {

        return (
          <div className='min-w-[300px] max-w-[336px] grow truncate px-4 py-3'>
            <Link to={`${Route.Discover}/?searchType=peptide&searchTerm=${peptideProperty}`} 
              onClick={closeDialog}
            >{peptideProperty}</Link> 
          </div>
        )
      }
      else {
        return <div className='px-5 py-3'>{peptideProperty}</div>
      }
    },
    // I added a custom sorting function here
    sortingFn: sortColumnsFn,
  }))
}
