import React, { Dispatch, SetStateAction, useState } from 'react'
import { ResultTypeContext } from '../contexts/context'
import ResultType from '../../types/ResultType'
import PeptidesResultTable from './PeptidesResultTable'
import { SearchType } from '../../pages/Validation'
import PeptideSamplesTable from './PeptidesSamplesTable'
import ProteinsResultTable from './ProteinsResultTable'
import PeptidePositionsTable from './PeptidePositionsTable'
import IntensityPlots from './IntensityPlots'
import { Section } from '../Section'

interface Props {
  searchType?: string
  searchTerm: string
  hlaFilter: string
  setFirstProteinId?: Dispatch<SetStateAction<string>>
}

const PeptideResults = ({ searchTerm, hlaFilter }: Props) => (
  <>
    <Section title='Proteins'>
      <ProteinsResultTable searchType={SearchType.peptide} searchTerm={searchTerm} />
    </Section>

    <PeptideSamplesTable peptide={searchTerm} hlaFilter={hlaFilter} />

    <IntensityPlots searchTerm={searchTerm} searchType={SearchType.peptide}
      hlaFilter={hlaFilter} />
  </>
)

const ProteinResults = ({ searchTerm, hlaFilter, setFirstMatchedProteinId }) => {

  return (
    <>
      <Section title='Proteins'>
        <ResultTypeContext.Provider value={ResultType.ProteinsResult}>
          <ProteinsResultTable searchType={SearchType.protein} searchTerm={searchTerm} setFirstProteinId={setFirstMatchedProteinId} />
        </ResultTypeContext.Provider>
      </Section>

      <Section>
        <ResultTypeContext.Provider value={ResultType.TargetValidationResult}>
          <PeptidesResultTable searchType={SearchType.protein} searchTerm={searchTerm}
            hlaFilter={hlaFilter} />
        </ResultTypeContext.Provider>
      </Section>

      <Section title='Positional Information'>
        {/* {firstMatchedProteinId != '' && <PositionalPlot proteinId={firstMatchedProteinId} hlaFilter={validationResults.hlaFilter} />} */}
        <ResultTypeContext.Provider value={ResultType.ProteinsResult}>
          <PeptidePositionsTable searchTerm={searchTerm} hlaFilter={hlaFilter} />
        </ResultTypeContext.Provider>
      </Section>

      <IntensityPlots searchTerm={searchTerm} searchType={SearchType.protein}
        hlaFilter={hlaFilter} />
    </>
  )
}

const ValidationResult = ({ searchType, searchTerm, hlaFilter }: Props) => {
  // reenable if positional plot is needed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars 
  const [ firstMatchedProteinId, setFirstMatchedProteinId ] = useState('')

  return (
    <div className='mt-6 space-y-6 border-t border-slate-200 pt-6'>
      {searchType === SearchType.peptide ? (
        <PeptideResults searchTerm={searchTerm} hlaFilter={hlaFilter} />
      ) : (
        <ProteinResults searchTerm={searchTerm} hlaFilter={hlaFilter} setFirstMatchedProteinId={setFirstMatchedProteinId} />
      )}
    </div>
  )
}

export default ValidationResult
