import React from 'react'
import useProteinPlots from '../../hooks/useProteinPlots'
import PlotImage from './PlotImage'
import CsvDownloadButton from '../offtarget/CsvDownloadButton'
import { Section } from '../Section'

function IntensityPlots({ searchTerm, searchType, hlaFilter }: {searchTerm: string, searchType: string, hlaFilter: string}) {
  const { isLoading, data } = useProteinPlots(searchType, searchTerm, hlaFilter)

  const filename = 'intensity_raw_data.csv'

  return (
    <Section title='Intensity Plots' downloadButton={data !== undefined && <CsvDownloadButton data={data['raw_data']} filename={filename} ></CsvDownloadButton>}>
      <PlotImage isLoading={isLoading} data={data} />
    </Section>
  )

}

export default IntensityPlots