import { useMemo } from 'react'
import PeptidesApiResponse from '../types/responses/PeptidesApiResponse'
import HeatmapItem from '../types/HeatmapItem'
import usePaginatedResults from './usePaginatedResults'
import useResultStore from '../store/resultStore'
import { HeatmapSorting, SortingDirection } from '../types/SortingDirection'
import useCategoryStore from '../store/categoryStore'

const useHeatmapData = (peptides: PeptidesApiResponse): { paginatedResults: HeatmapItem[], length: number } => {
  const { sortingDirection, sortBy } = useResultStore()
  const { categories } = useCategoryStore()

  const heatmapData = useMemo(() => {
    if (!categories) return []

    return peptides.reduce((acc, peptideApiItem) => {
      const { peptide, category, category_size, mean_normalized_intensity } = peptideApiItem
      const data = acc[peptide] ?? { count: { items: 0, samples: 0, intensity: 0 }, data: {}, peptide }

      data.count.items++
      data.count.samples += category_size
      data.count.intensity += mean_normalized_intensity
      data.data[category] = {
        positiveSampleCount: category_size,
        intensity: mean_normalized_intensity,
        categorySize: categories[category].sampleSum
      }

      acc[peptideApiItem.peptide] = data

      return acc
    }, {})
  }, [ peptides, categories ])

  const heatmapItems: HeatmapItem[] = Object.values(heatmapData)

  const sortedHeatmapData = useMemo(() => {
    return heatmapItems.sort((a, b) => {
      const sort = () => HeatmapSorting[sortBy](a, b)

      return (sortingDirection === SortingDirection.Descending) === sort() ? 1 : -1
    })
  }, [ sortBy, heatmapItems, sortingDirection ])

  const paginatedResults = usePaginatedResults(sortedHeatmapData)

  return { paginatedResults, length: Object.values(sortedHeatmapData).length }
}

export default useHeatmapData