import CategoryApiResponse from '../types/responses/CategoryApiResponse'
import { create } from 'zustand'

type CategoryStore = {
  categories?: CategoryApiResponse
  setCategories: (categories: CategoryApiResponse) => void
}

const useCategoryStore = create<CategoryStore>((set) => ({
  categories: null,
  setCategories: categories => set(() => ({ categories })),
}))

export default useCategoryStore