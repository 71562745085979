import React from 'react'
import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable, getFilteredRowModel } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../@/components/ui/table'
import { DataTablePagination } from '../pagination/DataTablePagination'
import { ScrollArea } from 'src/@/components/ui/scroll-area'
import Select from '../Select'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

export function FilterableDataTable<TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) {
  const [ sorting, setSorting ] = React.useState<SortingState>([])
  const [ globalFilter, setGlobalFilter ] = React.useState('')
  const [ selectedColumn, setSelectedColumn ] = React.useState<string | null>(null)

  const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedColumn(event.target.value)
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: (row, columnId, filterValue): boolean => {
      const filterValueLower = filterValue.toLowerCase()
      if (selectedColumn && selectedColumn !== 'any') {
        return row.getValue(selectedColumn)?.toString().toLowerCase().includes(filterValueLower)
      }

      return Object.keys(row.original).some((key) => row.getValue(key)?.toString().toLowerCase().includes(filterValueLower.toLowerCase()))
    },
  })

  return (
    <>
      <div className='mt-1 overflow-y-hidden rounded-md border-slate-200'>
        <div className='mb-4 flex items-center'>
          <span className='whitespace-nowrap pr-3'> Search Table: </span>
          <Select name='selectedDataTable' options={table.getAllColumns()} hasAnyColumn={true} handleChange={handleValueChange} />
          <input type='text' placeholder='Keywords' value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} className='ml-4 mr-2 h-12 rounded border p-1 text-gray-400 placeholder:text-gray-600 focus:text-black' data-testid='input-keywords' />
        </div>
        <ScrollArea className='h-[550px] w-max drop-shadow'>
          <Table>
            <TableHeader className='sticky top-0 z-10 w-fit pr-4 text-sm font-medium leading-snug text-slate-50'>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className=' bg-sky-500 p-0 text-left text-white' style={{ paddingLeft: '0.2rem' }}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody className='cursor-pointer bg-white text-sm'>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'} className='border-b border-slate-200 last:border-b-0 hover:bg-slate-100'>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className='truncate p-0 text-left' style={{ paddingLeft: '0.2rem' }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className='p-0 text-center'>
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
      <DataTablePagination table={table} />
    </>
  )
}
