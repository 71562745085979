import { useMemo } from 'react'
import useResultStore from '../store/resultStore'
import PeptidesApiResponse from '../types/responses/PeptidesApiResponse'

const useNotFoundPeptides = () => {
  const { result, userInput } = useResultStore()

  return useMemo(() => {
    if (!result) return []

    const peptides = (result as PeptidesApiResponse).reduce((acc, peptide) => {
      acc.push(peptide.peptide)

      return acc
    }, [])

    return userInput.filter(peptide => !peptides.includes(peptide))
  }, [ userInput, result ])
}

export default useNotFoundPeptides