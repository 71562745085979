import { useQuery } from '@tanstack/react-query'
import { getPeptide, getPeptideByProteinGene } from '../client/apiClient'

const usePeptides = (searchType: string, searchTerm: string, hlaFilter: string) => {
  return useQuery([ 'peptides', searchType, searchTerm, hlaFilter ], () => getPeptideByProteinGene(searchTerm, hlaFilter))
}

export const useSampledPeptides = (searchTerm: string, hlaFilter: string) => {
  return useQuery([ 'sampledPeptides', searchTerm, hlaFilter ], () => getPeptide(searchTerm, hlaFilter))
}

export default usePeptides