import React, { useEffect, useId, useState } from 'react'
import Loading from '../Loading'
import useDialogStore from '../../store/dialogStore'
import { DialogType, SampleInformationDialogType } from '../../types/Dialog'
import NoResultMessageBox from '../NoResultMessageBox'
import { FilterableDataTable } from '../tables/FilterableDataTable'
import { generateColumns } from './tablecolumns/PeptidesSamplesColumns'
import { useSampledPeptides } from 'src/hooks/usePeptides'
import { Section } from '../Section'
import CsvDownloadButton from '../offtarget/CsvDownloadButton'

type Props = {
  peptide: string
  hlaFilter: string
}

const PeptideSamplesTable = ({ peptide, hlaFilter }: Props) => {
  const { openDialog } = useDialogStore()
  const [ columns, setColumns ] = useState([])
  const { isLoading, data } = useSampledPeptides(peptide, hlaFilter)

  const fileId = useId()
  const filename = `alithea-samples-export_${fileId}.csv`

  const hasPeptides = data !== undefined && data.length > 0

  useEffect(() => {
    const openSampleInformationDialog = (sampleNumber: number) => {
      const sampleInformationDialog: SampleInformationDialogType = {
        type: DialogType.SampleInformation,
        payload: { sampleNumber }
      }
  
      openDialog(sampleInformationDialog)
    }

    setColumns(generateColumns(openSampleInformationDialog))
  }
  , [ data, openDialog ])

  interface SectionWrapperProps {
    children: React.ReactNode
  }

  function SectionWrapper({ children }: SectionWrapperProps) {
    return <Section title='Samples' downloadButton={hasPeptides && <CsvDownloadButton data={data} filename={filename} />}>
      {children}
    </Section>
  }

  if (isLoading) return <SectionWrapper><Loading label='Results are loading...' /></SectionWrapper>
  
  if (!hasPeptides) return <Section title='Samples' downloadButton={hasPeptides && <CsvDownloadButton data={data} filename={filename} />}>
    <NoResultMessageBox>There were no samples found!</NoResultMessageBox>
  </Section>

  return <Section title='Samples' downloadButton={hasPeptides && <CsvDownloadButton data={data} filename={filename} />}>
    <FilterableDataTable columns={columns} data={data} />
  </Section>
   
}

export default PeptideSamplesTable