import { MutableRefObject, useId } from 'react'

type Props = {
  hlaFilterRef: MutableRefObject<HTMLInputElement>
}

const HlaFilter = ({ hlaFilterRef }: Props) => {
  const filterId = useId()

  return (
    <div>
      <label htmlFor={filterId}>Filter by sample HLA type:</label>
      <input type='text' placeholder='(optional)' id={filterId} ref={hlaFilterRef} />
    </div>
  )
}

export default HlaFilter