import ResultType from '../types/ResultType'
import { ResultTypeContext } from '../components/contexts/context'
import SamplesResultsTable from 'src/components/samplesresultstable/SamplesResultsTable'
import Scrollbar from '../components/Scrollbar'

function Samples() {
  return (
    <Scrollbar>
      <ResultTypeContext.Provider value={ResultType.SamplesListResult}>
        <div className='mr-4 h-full w-full px-4 pb-4'>
          <div className='mt-8 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl'>
            <h2>Sample Overview</h2>

            <div className='space-y-6 bg-white p-6 shadow-sm'>
              <div className='mb-4 flex h-16 w-full items-center'>
                <h3 className='mb-0 mr-auto'>Results</h3>
              </div>

              <SamplesResultsTable />
            </div>
          </div>
        </div>
      </ResultTypeContext.Provider>
    </Scrollbar>
  )
}

export default Samples
