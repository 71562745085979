import { Badge, Button } from '@aws-amplify/ui-react'
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator'

type Props = {
  signOut: SignOut
  // eslint-disable-next-line
  user: any
}
const Header = ({ signOut, user }: Props) => {
  return (
    <div className='flex min-h-[60px] min-w-0 justify-end border-b border-slate-100 bg-white px-4'>
      <div className='flex w-full min-w-0 flex-none shrink-0 flex-row items-center pl-4'>
        <div>
          <h1 className='text-xl'>HLA Peptide Database</h1>
        </div>
        <div className='ml-auto flex items-center'>
          <div className='flex items-center gap-4'>
            <Badge>{user?.attributes?.name}</Badge>
            <Button onClick={signOut}>Sign out</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header