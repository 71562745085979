import Message from '../../types/Message'
import { toast } from 'react-toastify'

type Props = {
  message: Message
}

const FlashMessage = ({ message }: Props) => {
  const onMouseOver = () => toast.update(message.id, { autoClose: false })

  return (
    <div onMouseOver={onMouseOver} className='text-white'>
      <p className='mr-4 text-lg font-medium leading-5'>{message.heading}</p>
      {message.text &&
        <div className='mr-4 mt-4 font-normal leading-6'>
          {message.text}
        </div>
      }
    </div>
  )
}

export default FlashMessage