import Route from './route'
import { SortBy, SortingDirection } from '../types/SortingDirection'

export const MAX_ALLOWED_PEPTIDES = 500
export const DEFAULT_FLASH_MESSAGE_DURATION = 3000
export const MAX_INTENSITY_ON_SCALA = 10000
export const INTENSITY_SCALA_SPECTRUM = 160
export const LEGEND_GRADIENT_VALUES = [ 1, 5, 10, 50, 100, 250, 500, 600, 700, 800, 900, 1000, 2500, 5000, 7500, 10000 ]
export const LEGEND_LABELS = [ 1, 10, 100, 1000, 10000 ]

export const MenuEntries = [
  {
    path: Route.Home,
    label: 'Home',
    iconIdentifier: null
  },
  {
    path: Route.Discover,
    label: 'Target Validation',
    iconIdentifier: null
  },
  {
    path: Route.Offtarget,
    label: 'Toxicity Screening',
    iconIdentifier: null
  },
  {
    path: Route.Samples,
    label: 'Sample Overview',
    iconIdentifier: null
  }
]

export const DEFAULT_SORT_OF_PROPERTIES: Record<SortBy, SortingDirection> = {
  [SortBy.Peptide]: SortingDirection.Ascending,
  [SortBy.Intensity]: SortingDirection.Ascending,
  [SortBy.CategorySize]: SortingDirection.Ascending,
  [SortBy.Annotation]: SortingDirection.Ascending,
  [SortBy.Rank]: SortingDirection.Ascending,
  [SortBy.BestPredictedBinder]: SortingDirection.Ascending,
  [SortBy.SampleClass]: SortingDirection.Ascending,
  [SortBy.SampleNumber]: SortingDirection.Ascending,
  [SortBy.SampleHlaTyping]: SortingDirection.Ascending,
  [SortBy.SampleName]: SortingDirection.Ascending,
  [SortBy.NormalizedIntensity]: SortingDirection.Ascending,
  [SortBy.IdentifiedPeptides]: SortingDirection.Ascending,
  [SortBy.NewIds]: SortingDirection.Ascending,
  [SortBy.KnownIds]: SortingDirection.Ascending,
  [SortBy.EightToTwelveMers]: SortingDirection.Ascending,
  [SortBy.NewEightToTwelveMers]: SortingDirection.Ascending,
  [SortBy.TotalSignalIntensity]: SortingDirection.Ascending,
  [SortBy.AllPredictedHlaBinders]: SortingDirection.Ascending,
  [SortBy.HlaBinderEightToTwelveMers]: SortingDirection.Ascending,
}