import useDialogStore from '../../store/dialogStore'
import Loading from '../Loading'
import useSample from '../../hooks/useSample'
import SampleWithPeptides from '../../types/SampleWithPeptides'
import PeptideApiResponse from '../../types/responses/PeptideApiResponse'
import SampleInformationPeptideList from './SampleInformationPeptideList'

const SampleInformationDialog = () => {
  const { isLoading, data } = useSample()

  if (isLoading) return <Loading label='Loading sample data...' />

  return (
    <SampleInformationDialogContent sample={data} />
  )
}

type Props = {
  sample: SampleWithPeptides
}

const SampleInformationDialogContent = ({ sample }: Props) => {
  const { closeDialog } = useDialogStore()

  const coreData = [
    {
      label: 'Annotation',
      content: sample.annotation
    }, {
      label: 'Sample Class',
      content: sample.sample_class
    },
    {
      label: 'HLA Type',
      content: sample.sample_hla_typing
    },
    {
      label: 'Identified Peptides',
      content: sample.peptide_ids
    },
    {
      label: '8 to 12mers',
      content: sample.mers_8_to_twelve
    },
    {
      label: 'Total Signal Intensity',
      content: sample.total_intensity
    },
    {
      label: 'All Predicted HLA Binders',
      content: sample.hla_binder_all_predicted
    },
    {
      label: 'HLA Binder 8 to 12mers',
      content: sample.hla_binder_8_to_12mer
    }
  ]

  return (
    <>
      <h4>{sample.sample_name} (#{sample.sample_number})</h4>
      <div className='flex flex-col'>
        {coreData.map(item => (
          <div className='flex space-x-4 py-2 hover:bg-slate-100' key={item.label}>
            <div className='w-52 pl-2'>{item.label}</div>
            <div>{item.content}</div>
          </div>
        ))}
      </div>

      <SampleInformationPeptideList peptideList={sample.peptideList as any as PeptideApiResponse} />
     
      <div className='mt-8 flex justify-end space-x-4'>
        <button type='reset' className='button button-reset--active' onClick={closeDialog}>
          Close
        </button>
      </div>
    </>
  )
}

export default SampleInformationDialog