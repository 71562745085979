type Category = {
  category_name: string
  samples_in_category: number
  category_type: CategoryType
}

export enum CategoryType {
  Tissue = 'healthy tissue',
  PrimaryCells = 'healthy primary cells',
  Plasma = 'healthy plasma'
}

export default Category