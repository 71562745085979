import intensityToHsl from './intensityToHsl'
import { LEGEND_GRADIENT_VALUES } from '../config/config'

const getBackgroundGradient = () => {
  return LEGEND_GRADIENT_VALUES.reduce((acc, value) => {
    const percentage = Math.log10(value) * 25

    acc += `, ${intensityToHsl(value)} ${percentage}%`

    return acc
  }, 'to right')
}

export default getBackgroundGradient