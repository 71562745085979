import { DialogType } from '../types/Dialog'
import DialogContent from '../types/DialogContent'
import SampleInformationDialog from '../components/dialogs/SampleInformationDialog'

const DialogConfig: Record<DialogType, DialogContent> = {
  [DialogType.SampleInformation]: {
    content: <SampleInformationDialog />,
    title: 'Sample Information'
  }
}

export default DialogConfig