import { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Route from '../../config/route'
import { useLocation } from 'react-router'

type Props = {
  label: string
  onClick?: () => void
  route?: Route
}

const NavigationOption = ({ label, onClick = () => void 0, route }: Props) => {
  const [ collapsed ] = useState(false)
  const { pathname } = useLocation()

  const active = pathname === route

  const navigationItem = <li onClick={onClick} className={classNames(
    'group transition-opacity cursor-pointer border-l-4 py-4 relative',
    {
      'bg-slate-300 text-slate-900 border-sky-500': active,
      'text-slate-700 hover:bg-slate-300 hover:text-slate-900 border-transparent': !active,
      'flex items-center': !collapsed
    }
  )}>
    {(label && !collapsed) && (
      <div className='ml-4 flex grow place-content-center items-center justify-between break-words text-sm leading-none'>
        <span>{label}</span>
      </div>
    )}
  </li>

  return (
    <>
      {(label && collapsed) && (
        <div className='flex place-content-center items-center'>
          <span>{label}</span>
        </div>
      )}

      {route ? <Link to={route}>{navigationItem}</Link> : navigationItem}
    </>
  )
}
export default NavigationOption