import Scrollbar from 'src/components/Scrollbar'
import SampleInformationPeptideListTable from 'src/components/dialogs/SampleInformationPeptideListTable'
import { PeptideApiItem } from 'src/types/responses/PeptideApiResponse'

interface Props {
  peptideList: PeptideApiItem[]
}

function SampleInformationPeptideList(props: Props) {
  return (
    <Scrollbar>
      <div className='mr-4 h-full w-full px-4 pb-4'>
        <div className='mt-8 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl'>
          <h4 className='mt-8'>Peptide List</h4>
          <SampleInformationPeptideListTable peptideList={props.peptideList} />
        </div>
      </div>
    </Scrollbar>
  )
}

export default SampleInformationPeptideList
