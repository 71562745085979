export enum DialogType {
  SampleInformation = 'sample-information'
}

interface Dialog {
  type: DialogType
  payload: any
}

export interface SampleInformationDialogType extends Dialog {
  payload: {
    sampleNumber: number
  }
}

export default Dialog