import Protein from 'src/types/Protein'
import ProteinsApiResponse from 'src/types/responses/ProteinsApiResponse'

export function matchPeptide(sequence, peptide) {
  const regex = new RegExp(peptide, 'i')
  const match = sequence.match(regex)

  if (match == null) return Number.NEGATIVE_INFINITY 
  if (match == false) return 0

  return match.index + 1
}
  
export function matchPeptideInProteinSequence(data: ProteinsApiResponse, peptide: string): Protein[] {
    
  const x = data.map((protein) => {
    const firstMatchedPos = matchPeptide(protein.protein_seq, peptide)
  
    return { ...protein, first_matched_pos: firstMatchedPos }
  })
  
  return x
}