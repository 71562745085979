interface SectionProps {
  title?: string
  children: React.ReactNode
  downloadButton?: React.ReactNode
}

export const Section = ({ title, children, downloadButton }: SectionProps) => (
  <div className='space-y-6 bg-white p-6 shadow-sm'>
    {title && (
      <div className='mb-4 flex h-16 w-full items-center'>
        <h3 className='mb-0 mr-auto'>{title}</h3>
        {downloadButton}
      </div>
    )}

    {children}
  </div>
)