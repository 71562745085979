import classNames from 'classnames'
import React, { BaseSyntheticEvent, useId, useRef } from 'react'
import { getCategories, postPeptides } from '../../client/apiClient'
import useResultStore from '../../store/resultStore'
import useCategoryStore from '../../store/categoryStore'
import transformPeptideUserInput from '../../lib/transformPeptideUserInput'
import HlaFilter from '../HlaFilter'

const OffTargetForm = () => {
  const { setResult, reset, setLoading, loading, setUserInput, setHlaFilter } = useResultStore()
  const { setCategories } = useCategoryStore()
  const inputRef = useRef<HTMLTextAreaElement>()
  const peptideId = useId()
  const hlaFilterRef = useRef<HTMLInputElement>()

  const onSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault()
    if (loading) return

    setLoading(true)
    reset()

    const peptideList = transformPeptideUserInput(inputRef.current.value)
    setUserInput(peptideList)
    setHlaFilter(hlaFilterRef.current.value)

    const promises = [
      postPeptides(peptideList, hlaFilterRef.current.value).then(response => setResult(response)),
      getCategories(hlaFilterRef.current.value).then(response => setCategories(response))
    ]

    await Promise.all(promises)

    setLoading(false)
  }

  const resetForm = () => {
    reset()
    setLoading(false)
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='flex w-full space-x-4'>
        <div className='grow'>
          <label htmlFor={peptideId}>Input List:</label>
          <textarea id={peptideId} placeholder='Please enter peptides (as comma separated list)' required ref={inputRef} />
        </div>
        <HlaFilter hlaFilterRef={hlaFilterRef} />
      </div>
      <div className='mt-2 flex items-center space-x-4'>
        <button type='submit' className={classNames({ 'button-primary--active': !loading, 'button-primary--disabled': loading })}>
          Submit
        </button>
        <button type='reset' className={classNames({ 'button-reset--active': !loading, 'button-reset--disabled': loading })} onClick={resetForm}>
          Reset
        </button>
      </div>
    </form>
  )
}

export default OffTargetForm