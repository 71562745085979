import addFlashMessage from 'src/lib/messages'
import Loading from '../Loading'
import React from 'react'
import NoResultMessageBox from '../NoResultMessageBox'

interface PlotImageProps{
    isLoading: boolean
    data: any
    hasScoringPlot?: boolean
}

const PlotImage: React.FC<PlotImageProps> = ({ isLoading, data, hasScoringPlot }) => {

  if (isLoading && !hasScoringPlot) return <Loading label='Results are loading...' />

  if (data === undefined || data.resultLinks === null || data.data === null) return <NoResultMessageBox>No plots generated because there were no samples found!</NoResultMessageBox>

  if (data.errorMessage) {
    addFlashMessage('Error generating intensity plots', data.errorMessage, 'error')

    return <div>No plots generated.</div>
  }
    
  const resultLinks = data.resultLinks ? data.resultLinks : [ data ]

  return (
    <>
      {resultLinks.map((resultLink: any, index: number) => {
        if (resultLink === null) return null

        return (
          <div className='flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl' key={index}>
            <img
              className='object-scale-down'
              src={`data:image/svg+xml;base64,${resultLink.data || resultLink}`}
              alt={`Plot Image ${index}`}
            />
          </div>
        )
      })}
    </>
  )

}

export default PlotImage