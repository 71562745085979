const Footer = () => {
  return (
    <div className='mt-6 flex'>
      <span className='mx-auto'>
        Interested in more details?
        <a href='mailto:database@alithea-bio.com' className='pl-1'>Request a demo</a>
      </span>
    </div>
  )
}

export default Footer