import classNames from 'classnames'
import { Fragment } from 'react'
import useResultStore from '../../store/resultStore'

type Props = {
  pageCount: number
}

const PageSelection = ({ pageCount }: Props) => {
  const { page, setPage } = useResultStore()

  const isFirstPage = page === 1
  const isLastPage = page === pageCount

  const previousPage = () => page > 1 && setPage(page - 1)
  const nextPage = () => page < pageCount && setPage(page + 1)

  return (
    <nav className='relative z-0 inline-flex select-none -space-x-px text-slate-500 shadow-sm'>
      <a onClick={previousPage} className={classNames('w-10 py-2 border border-slate-200', { 'bg-slate-200': isFirstPage, 'cursor-pointer bg-white hover:bg-slate-200': !isFirstPage })}>{'<'}</a>
      {[ ...Array(pageCount) ].map((_, index) => {
        const actualPage = ++index

        const isFirst = actualPage === 1
        const isLast = actualPage === pageCount

        const insertDescriptiveVariableNameHere = actualPage <= page + 2 && actualPage >= page - 2
        const insertDescriptiveVariableNameForFirstThingHere = pageCount > 7 && page > 4 && actualPage < page + 2
        const insertDescriptiveVariableNameForFirstLastHere = pageCount > 7 && page < pageCount - 3
        const isHidden = !(insertDescriptiveVariableNameHere || isFirst || isLast)
        const isActive = actualPage === page

        if (isHidden) return null

        const Dots = () => <span className='border border-slate-200 bg-white px-4 py-2 font-medium'>…</span> // Check with concatenation

        return (
          <Fragment key={index}>
            {isLast && insertDescriptiveVariableNameForFirstLastHere && <Dots />} 
            <a onClick={() => setPage(actualPage)} className={classNames('w-10 cursor-pointer border py-2 font-medium', { 'border-sky-500 bg-sky-500 text-slate-50 hover:text-white': isActive, 'border-slate-200 bg-white hover:bg-slate-200': !isActive })}>
              {actualPage}
            </a>
            {isFirst && insertDescriptiveVariableNameForFirstThingHere && <Dots />}
          </Fragment>
        )
      })
      }
      <a onClick={nextPage} className={classNames('w-10 border border-slate-200 py-2', { 'bg-slate-200': isLastPage, 'cursor-pointer bg-white hover:bg-slate-200': !isLastPage })}>{'>'}</a>
    </nav>
  )
}

export default PageSelection