import { useQuery } from '@tanstack/react-query'
import { getPositionalPlot, getIntensityPlots } from '../client/apiClient'

const useIntensityPlots = (searchType: string, searchTerm: string, hlaFilter: string) => {
  return useQuery([ 'proteinPlots', searchType, searchTerm, hlaFilter ], () => getIntensityPlots(searchType, searchTerm, hlaFilter))
}

export const usePositionalPlot = (proteinId: string, hlaFilter: string) => {
  return useQuery([ 'positionalPlot', hlaFilter ], () => getPositionalPlot(proteinId, hlaFilter))
}

export default useIntensityPlots