import Loading from '../Loading'
import { FilterableDataTable } from '../tables/FilterableDataTable'
import { generateColumns } from 'src/components/dialogs/SampleInformationPeptideListTableColumn'
import useDialogStore from 'src/store/dialogStore'
import { useEffect, useState } from 'react'
import { PeptideApiItem } from 'src/types/responses/PeptideApiResponse'

interface Props {
  peptideList: PeptideApiItem[]
}

const SampleInformationPeptideListTable = (props: Props) => {
  const { closeDialog } = useDialogStore()
  const [ columns, setColumns ] = useState([])
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    setColumns(generateColumns(closeDialog))
    setLoading(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [ closeDialog ])

  if (loading) return <Loading label='Data is loading...' />

  return (
    <FilterableDataTable columns={columns} data={props.peptideList} />
  )
}

export default SampleInformationPeptideListTable
