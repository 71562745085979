import { useMemo } from 'react'
import useResultStore from '../store/resultStore'
import useCategoryStore from '../store/categoryStore'
import PeptidesApiResponse from '../types/responses/PeptidesApiResponse'

const usePeptidesCsvExport = () => {
  const { result, userInput } = useResultStore()
  const { categories } = useCategoryStore()

  return useMemo(() => {
    if (result == null || categories == null) return
    const plainCategories = Object.keys(categories)
    const apiResponse = result as PeptidesApiResponse

    return transformForExport(userInput, apiResponse, plainCategories)
  }, [ categories, userInput, result ])
}

export function transformForExport(userInputList: string[], apiResponse: any[], categories: string[]) {

  const byPeptide = groupByPeptide(apiResponse)

  return userInputList.reduce((acc, peptide) => {
    if (!byPeptide[peptide]) {
      acc.push({ peptide: peptide, ...addZeroes(categories) })

      return acc
    }

    acc.push({ peptide: peptide, ...categories.reduce((obj, category) => {
      obj[category] = (!byPeptide[peptide][category]) ? 0 : byPeptide[peptide][category].mean_normalized_intensity

      return obj
    }, []) })

    return acc
  }, [])

}

const groupByPeptide = (apiResponse: PeptidesApiResponse) => apiResponse.reduce((acc, current) => {
  const { peptide, category } = current

  if (!acc[peptide]) acc[peptide] = {}

  acc[peptide][category] = current

  return acc
}, {})

const addZeroes = (categories: string[]) => categories.reduce((obj, category) => {
  obj[category] = 0

  return obj
}, [])

export default usePeptidesCsvExport