import useResultStore from '../store/resultStore'

const usePaginatedResults = (sortedResults: any[]) => {
  const { entriesPerPage, page } = useResultStore()
  const pageTill = ((page * entriesPerPage) < sortedResults.length) ? page * entriesPerPage : sortedResults.length
  const pageFrom = pageTill === 0 ? 0 : (page - 1) * entriesPerPage + 1

  return sortedResults.slice(pageFrom - 1, pageTill)
}

export default usePaginatedResults