import Legend from '../Legend'
import { Link } from 'react-router-dom'
import Route from '../../../config/route'
import useHeatmapData from '../../../hooks/useHeatmapData'
import useResultStore from '../../../store/resultStore'
import PeptidesApiResponse from '../../../types/responses/PeptidesApiResponse'
import SampleCounts from './SampleCounts'
import Intensities from './Intensities'
import CategoryRow from './CategoryRow'
import Pagination from '../../pagination/Pagination'

const Heatmap = () => {
  const { result, loading } = useResultStore()
  const { paginatedResults, length } = useHeatmapData((result ?? []) as PeptidesApiResponse)

  if (loading || !result || result.length === 0) return null

  return (
    <>
      <div className='flex bg-slate-200'>
        <div className='space-y-0.5 pr-1.5 text-xs'>
          {paginatedResults.map(({ peptide }) => (
            <div className='flex h-8 shrink-0 items-center bg-white pr-1' key={peptide}>
              <Link to={`${Route.Discover}/?searchType=peptide&searchTerm=${peptide}`}>{peptide}</Link>
            </div>
          ))}
          <div className='box-content h-64 shrink-0 border-b-4  border-white bg-white pr-1' />
        </div>
        <div className='space-y-0.5 overflow-x-scroll'>
          <Intensities heatmapItems={paginatedResults} />
          <CategoryRow />
        </div>

        <SampleCounts heatmapItems={paginatedResults} />
      </div>
      <div className='pt-8'>
        <Pagination entriesCount={length} showSelectAmount={false} />
        <Legend />
      </div>
    </>
  )
}

export default Heatmap
