import { Tooltip } from 'react-tooltip'
import { Fragment } from 'react'
import HeatmapItem from '../../../types/HeatmapItem'
import intensityToHsl from '../../../lib/intensityToHsl'
import useCategories from '../../../hooks/useCategories'

type Props = {
  heatmapItems: HeatmapItem[]
}

const Intensities = ({ heatmapItems }: Props) => {
  const categories = useCategories()

  return (
    <div className='space-y-0.5'>
      {heatmapItems.map(({ data, peptide }) => {
        return (
          <div key={peptide} className='flex space-x-0.5'>
            {categories.map((category, index) => {
              const categoryData = data[category.name] ?? null
              const tooltipId = `${peptide}-${index}`

              const tooltip = (
                <Tooltip id={tooltipId}>
                  <div className='flex space-x-4'>
                    <div className='font-medium'>
                      Peptide:<br />
                      Category:<br />
                      Mean Normalized Intensity: <br />
                      Positive Sample Count:
                    </div>
                    <div>
                      {peptide}<br />
                      {category.name}<br />
                      {categoryData ? Intl.NumberFormat(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(categoryData.intensity) : 'n/a'}<br />
                      {categoryData ? Intl.NumberFormat().format(categoryData.positiveSampleCount) : 'n/a'}
                    </div>
                  </div>
                </Tooltip>
              )

              if (!categoryData) return (
                <Fragment key={index}>
                  <div className='h-8 w-6 shrink-0 cursor-help bg-slate-200 hover:brightness-90' data-tooltip-id={tooltipId} />
                  {tooltip}
                </Fragment>
              )

              return (
                <Fragment key={index}>
                  <div className='h-8 w-6 shrink-0 cursor-help hover:brightness-90' style={{ backgroundColor: intensityToHsl(categoryData.intensity) }} data-tooltip-id={tooltipId} />
                  {tooltip}
                </Fragment>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Intensities