import React from 'react'
import Scrollbar from '../components/Scrollbar'
import { ResultTypeContext } from '../components/contexts/context'
import ResultType from '../types/ResultType'
import OffTargetForm from '../components/offtarget/OffTargetForm'
import OffTargetResult from '../components/offtarget/OffTargetResult'

function Offtarget() {
  return (
    <Scrollbar>
      <ResultTypeContext.Provider value={ResultType.OffTargetTableResult}>
        <div className='mt-8 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl'>
          <h2>Off-target Toxicity Analysis</h2>
          <OffTargetForm />
          <OffTargetResult />
        </div>
      </ResultTypeContext.Provider>
    </Scrollbar>
  )
}

export default Offtarget
