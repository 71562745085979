import NavigationSection from './NavigationSection'
import Logo from './Logo'
import NavigationOption from './NavigationOption'
import SupportMailLink from '../SupportMailLink'
import { MenuEntries } from '../../config/config'

const Navigation = () => {
  return (
    <div className='flex w-72 flex-col items-stretch'>
      <nav className='flex flex-1 flex-col justify-between justify-items-stretch bg-slate-200'>
        <div className='mb-4'>
          <NavigationSection>
            <Logo />
          </NavigationSection>

          <NavigationSection margin>
            {MenuEntries.map((menuEntry, index) => {
              const { label, path } = menuEntry

              return <NavigationOption key={index} label={label} route={path} />
            })}
          </NavigationSection>
        </div>

        <NavigationSection margin>
          <SupportMailLink />
        </NavigationSection>
      </nav>
    </div>
  )
}

export default Navigation