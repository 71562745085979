import PeptidesApiResponse from './responses/PeptidesApiResponse'
import { PeptideApiItem } from './responses/PeptideApiResponse'
import HeatmapItem from './HeatmapItem'
import Sample from './Sample'

type Filter = {
  sortingDirection: SortingDirection
  sortBy: keyof PeptidesApiResponse
}

export enum SortingDirection {
  Ascending = 'asc',
  Descending = 'desc'
}

export enum SortBy {
  Peptide = 'peptide',
  CategorySize = 'category_size',
  Intensity = 'mean_normalized_intensity',
  Annotation = 'annotation',
  BestPredictedBinder = 'best_predicted_binder',
  Rank = 'rank',
  SampleClass = 'sample_class',
  SampleNumber = 'sample_number',
  SampleHlaTyping = 'sample_hla_typing',
  NormalizedIntensity = 'normalized_intensity',
  SampleName = 'sample_name',
  IdentifiedPeptides = 'identified_peptides',
  NewIds = 'new_ids',
  KnownIds = 'known_ids',
  EightToTwelveMers = '8_to_12_mers',
  NewEightToTwelveMers = 'new_8_to_12_mers',
  TotalSignalIntensity = 'total_signal_intensity',
  AllPredictedHlaBinders = 'all_predicted_hla_binders',
  HlaBinderEightToTwelveMers = 'hla_binder_8_to_12_mers',
}

export const SampleSorting: {[ key in SortBy ]?: (a: Sample, b: Sample) => boolean} = {
  [SortBy.SampleNumber]: (a, b) => a.sample_number < b.sample_number,
  [SortBy.SampleName]: (a, b) => a.sample_name.localeCompare(b.sample_name) === -1,
  [SortBy.Annotation]: (a, b) => a.annotation.localeCompare(b.annotation) === -1,
  [SortBy.SampleClass]: (a, b) => a.sample_class.localeCompare(b.sample_class) === -1,
  [SortBy.SampleHlaTyping]: (a, b) => a.sample_hla_typing.localeCompare(b.sample_hla_typing) === -1,
  [SortBy.IdentifiedPeptides]: (a, b) => a.peptide_ids < b.peptide_ids,
  [SortBy.EightToTwelveMers]: (a, b) => a.mers_8_to_twelve < b.mers_8_to_twelve,
  [SortBy.TotalSignalIntensity]: (a, b) => a.total_intensity < b.total_intensity,
  [SortBy.AllPredictedHlaBinders]: (a, b) => a.hla_binder_all_predicted < b.hla_binder_all_predicted,
  [SortBy.HlaBinderEightToTwelveMers]: (a, b) => a.hla_binder_8_to_12mer < b.hla_binder_8_to_12mer,
}

export const HeatmapSorting: {[ key in SortBy ]?: (a: HeatmapItem, b: HeatmapItem) => boolean} = {
  [SortBy.Peptide]: (a, b) => a.peptide.localeCompare(b.peptide) === -1,
  [SortBy.Intensity]: (a, b) => a.count.intensity < b.count.intensity,
  [SortBy.CategorySize]: (a, b) => a.count.items < b.count.items,
}

export const PeptideSorting: {[ key in SortBy ]?: (a: PeptideApiItem, b: PeptideApiItem) => boolean} = {
  [SortBy.Peptide]: (a, b) => a.peptide.localeCompare(b.peptide) === -1,
  [SortBy.Annotation]: (a, b) => a.annotation.localeCompare(b.annotation) === -1,
  [SortBy.SampleNumber]: (a, b) => a.sample_number < b.sample_number,
  [SortBy.Rank]: (a, b) => a.rank < b.rank,
  [SortBy.SampleClass]: (a, b) => a.sample_class.localeCompare(b.sample_class) === -1,
  [SortBy.BestPredictedBinder]: (a, b) => a.best_predicted_binder.localeCompare(b.best_predicted_binder) === -1,
  [SortBy.SampleHlaTyping]: (a, b) => a.sample_hla_typing.localeCompare(b.sample_hla_typing) === -1,
  [SortBy.NormalizedIntensity]: (a, b) => a.normalized_intensity < b.normalized_intensity,
  [SortBy.SampleName]: (a, b) => a.sample_name.localeCompare(b.sample_name) === -1,
}

export default Filter