import { Column, ColumnDef } from '@tanstack/react-table'
import { snakeCaseToTitleCase } from 'src/helpers'
import Sample from 'src/types/Sample'

interface SelectProps {
  name: string
  defaultValue?: any
  options: Option[] | Column<Sample>[] | ColumnDef<Sample, unknown>[]
  handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  hasAnyColumn?: boolean
}

interface Option {
  key: number
  value: string
  label?: string
  id?: string
}

const Select = ({ name, defaultValue, options, handleChange, hasAnyColumn }: SelectProps) => {
  return (
    <select name={name} defaultValue={defaultValue} className='ml-2 mr-4 rounded border border-gray-300 bg-white px-4 py-2 pr-8 ' onChange={handleChange} data-testid='select-column'>
      {hasAnyColumn && (
        <>
          <option key='any-column' value='any' className='text-gray-500'>
            Any Column
          </option>
        </>
      )}
      {options.map((option, index) => (
        <option key={`option-${index}-${option.key || option.id}`} value={option.value || option.id}>
          {option.label || snakeCaseToTitleCase(option.id)}
        </option>
      ))}
    </select>
  )
}

export default Select
