import useCategoryStore from '../store/categoryStore'
import { useMemo } from 'react'
import useResultStore from '../store/resultStore'
import { CategoryApiItem } from '../types/responses/CategoryApiResponse'
import PeptidesApiResponse from '../types/responses/PeptidesApiResponse'

const useCategories = (): CategoryApiItem[] => {
  const { categories } = useCategoryStore()
  const { result } = useResultStore()

  return useMemo(() => {
    const peptides = result as PeptidesApiResponse

    if (peptides) {
      peptides.map(peptidesApiItem => {
        const { category, category_size, mean_normalized_intensity } = peptidesApiItem

        categories[category].sampleSum += category_size
        categories[category].size++
        categories[category].intensity += mean_normalized_intensity
      })
    }

    const categoryAsArray = Object.values(categories)

    return categoryAsArray.sort((a, b) => a.name.localeCompare(b.name))
  }, [ categories, result ])
}

export default useCategories