import { create } from 'zustand'
import Dialog from '../types/Dialog'

type DialogStore = {
  dialog?: Dialog
  openDialog: (dialog: Dialog) => void
  closeDialog: () => void
}

const useDialogStore = create<DialogStore>(set => ({
  dialog: null,
  openDialog: dialog => set(() => ({ dialog })),
  closeDialog: () => set(() => ({ dialog: null }))
}))

export default useDialogStore