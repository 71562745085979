import { Authenticator } from '@aws-amplify/ui-react'
import { Outlet } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents'
import AuthHeader from './components/auth/Header'
import AuthFooter from './components/auth/Footer'
import Header from './components/app/Header'
import Navigation from './components/app/Navigation'
import { toast, ToastContainer } from 'react-toastify'
import DialogContainer from './components/DialogContainer'
import { QueryClient } from '@tanstack/query-core'
import { QueryClientProvider } from '@tanstack/react-query'

const authComponents: DefaultComponents = {
  Header: AuthHeader,
  Footer: AuthFooter
}

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='cursor-default select-none overflow-hidden'>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} closeOnClick={false} theme='colored' draggable={false} />
        <DialogContainer />

        <div className='relative h-screen w-screen bg-slate-100 bg-cover bg-bottom bg-no-repeat font-sans text-base font-light text-slate-700'>
          <Authenticator loginMechanisms={[ 'email' ]} components={authComponents} hideSignUp>
            {({ signOut, user }) => (
              <div className='relative z-10 flex h-screen w-full flex-1 justify-between'>
                <Navigation />
                <div className='flex-1 overflow-y-auto'>
                  <div className='flex h-screen min-w-0 flex-col'>
                    <Header signOut={signOut} user={user} />
                    <main className='mb-4 ml-4 flex h-full flex-row justify-between overflow-hidden'>
                      <Outlet />
                    </main>
                  </div>
                </div>
              </div>
            )}
          </Authenticator>
        </div>
      </div>
    </QueryClientProvider>
  )
}

export default App
