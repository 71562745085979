import classNames from 'classnames'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  border?: boolean
  margin?: boolean
}

const NavigationSection = ({ children, border = false, margin = false }: Props) => {
  return (
    <ul className={classNames({ 'border-b border-slate-200 pb-4': border, 'mb-4': margin })}>
      {children}
    </ul>
  )
}

export default NavigationSection