import React, { useEffect, useState } from 'react'
import Loading from '../Loading'
import NoResultMessageBox from '../NoResultMessageBox'
import { generateColumns } from './tablecolumns/PeptidePositionResultColumns'
import { PeptidePositionSpecialFilterTable } from '../tables/PeptidePositionSpecialFilterTable'
import { useQueries } from '@tanstack/react-query'
import { getPeptidePositions, getProteins } from 'src/client/apiClient'

type Props = {
  searchTerm: string
  hlaFilter: string
}

const PeptidePositionsTable = ({ searchTerm, hlaFilter }: Props) => {
  
  const results = useQueries({
    queries: [ 
      { queryKey: [ 'proteins', searchTerm ], queryFn: () => getProteins('proteins', searchTerm) },
      { queryKey: [ 'peptidePositions', searchTerm, hlaFilter ], queryFn: () => getPeptidePositions(searchTerm, hlaFilter) }
    ]
  })   
  
  const { isLoading: isProteinsLoading, data: proteins } = results[0]
  const { isLoading, data } = results[1]
  const [ columns, setColumns ] = useState([])

  useEffect(() => {
    if (!data) return
    setColumns(generateColumns())
  }
  , [ data ])

  if (isLoading || isProteinsLoading) return <Loading label='Results are loading...' />
  if (data === undefined || data.length === 0 || proteins === undefined || proteins.length === 0) return <NoResultMessageBox>There was no positional information found!</NoResultMessageBox>

  return <>
    <PeptidePositionSpecialFilterTable columns={columns} data={data} proteins={proteins} />
  </>
}

export default PeptidePositionsTable
