import { Button } from 'src/@/components/ui/button'
import { ColumnDef, FilterFn, filterFns, sortingFns } from '@tanstack/react-table'
import { ArrowUpDown } from 'lucide-react'
import { Link } from 'react-router-dom'
import Route from '../../../config/route'
import Protein from 'src/types/Protein'

const CustomHeaderButton: React.FC<{
  columnName: string
  column: any
  classNameProp: string
}> = ({ columnName, column, classNameProp }) => (
  <Button
    variant='ghost'
    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    className={`${classNameProp} cursor-pointer py-3 pl-4`}
  >
    <span className='text-left'>{columnName}</span>
    <ArrowUpDown className='ml-1 h-4 w-4' />
  </Button>
)

const columnNames = [
  'peptide_seq',
  'protein_id',
  'first_aa_position',
  'last_aa_position',
  'peptide_count',
]  

const columnMapping: Record<
  string,
  { headerText: string, classNameProp: string, filterFn: FilterFn<Protein> }
> = {
  peptide_seq: { headerText: 'Peptide', filterFn: filterFns.includesString, classNameProp: 'w-56', },
  protein_id: { headerText: 'Uniprot Identifier', filterFn: filterFns.includesString, classNameProp: 'w-56', },
  first_aa_position: { headerText: 'Start: First aa position', filterFn: after, classNameProp: 'w-56 shrink', },
  last_aa_position: { headerText: 'End: Last aa position', filterFn: before, classNameProp: 'w-56 shrink', },
  peptide_count: { headerText: 'Number of hits', filterFn: filterFns.includesString, classNameProp: 'w-56 shrink', },
}

function before (row, columnId, filterValue): boolean {
  return Number.parseInt(row.getValue(columnId)) >= filterValue
}

function after (row, columnId, filterValue): boolean {
  return Number.parseInt(row.getValue(columnId)) <= filterValue
}

export const generateColumns = (): ColumnDef<Protein>[] => {
  return columnNames.map((columnName) => ({
    accessorKey: columnName,
    header: ({ column }) => (
      <CustomHeaderButton
        columnName={columnMapping[columnName].headerText}
        column={column}
        classNameProp={columnMapping[columnName].classNameProp}
      />
    ),
    cell: ({ row }) => {
      const peptideProperty = row.original[columnName]
      switch (true) {
        case columnName === 'peptide_seq':
          return <Link className='px-5 py-3' to={`${Route.Discover}?searchType=peptide&searchTerm=${peptideProperty}`} reloadDocument>{peptideProperty}</Link>

        case columnName === 'protein_id' || columnName === 'gene_name':
          return (
            <div className='link shrink whitespace-nowrap px-5 py-3'>
              <Link to={`${Route.Discover}/?searchType=protein&searchTerm=${peptideProperty}`} reloadDocument>{peptideProperty}</Link>
            </div>
          )
        default:
          return <div className='whitespace-nowrap px-5 py-3' data-testid={`${columnName}-${row.index}`}>{peptideProperty}</div>
      }
    },
    sortingFn: sortingFns.alphanumeric,
    filterFn: columnMapping[columnName].filterFn,
  }))
}
