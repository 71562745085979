import React, { BaseSyntheticEvent, useCallback, useEffect, useId, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import Scrollbar from '../components/Scrollbar'
import { ResultTypeContext } from '../components/contexts/context'
import ResultType from '../types/ResultType'
import ToOfftargetButton from '../components/discover/ToOfftargetButton'
import HlaFilter from '../components/HlaFilter'
import Select from '../components/Select'
import ValidationResult from '../components/discover/ValidationResult'

export enum SearchType {
  peptide = 'peptide',
  protein = 'protein',
}

function Validation() {
  const searchTypeOptions = [
    { key: 1, label: 'Peptide', value: SearchType.peptide },
    { key: 2, label: 'Protein/Gene', value: SearchType.protein },
  ]

  const [ searchParams, setSearchParams ] = useSearchParams()
  const term = searchParams.get('searchTerm')
  const type = searchParams.get('searchType')
  const [ peptideLoading, setPeptideLoading ] = useState(false)
  const [ formSubmit, setFormSubmit ] = useState(false)
  const peptideRef = useRef<HTMLInputElement>()
  const hlaFilterRef = useRef<HTMLInputElement>()
  const peptideId = useId()

  const loadSampleData = useCallback(
    async (searchType: string) => {
      if (peptideLoading) return

      setPeptideLoading(true)
      setFormSubmit(false)

      if (searchType !== SearchType.peptide) {
        setPeptideLoading(false)
      }
      setFormSubmit(true)
    },
    [ peptideLoading ]
  )

  useEffect(() => {
    if ((term && !peptideRef.current.value) || (term && peptideRef.current.value != term)) {
      peptideRef.current.value = term
      loadSampleData(searchParams.get('searchType'))
    }
  }, [ term, searchParams, loadSampleData ])

  const onSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault()

    const form = event.target
    const selectedSearchType = new FormData(form).get('selectedSearchType').toString()

    setSearchParams({ searchType: selectedSearchType, searchTerm: peptideRef.current.value })
    await loadSampleData(selectedSearchType)
    setPeptideLoading(false)
  }

  return (
    <Scrollbar>
      <div className='mt-8 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl'>
        <h2>Target Validation</h2>

        <form onSubmit={onSubmit}>
          <div className='flex w-full items-center gap-4'>
            <div>
              <label htmlFor={peptideId}>Search For:</label>
              <Select name='selectedSearchType' defaultValue={type} options={searchTypeOptions} hasAnyColumn={false} />
            </div>
            <div>
              <label htmlFor={peptideId}>Search Term:</label>
              <input type='text' placeholder='Please enter peptide' required id={peptideId} ref={peptideRef} />
            </div>
            <HlaFilter hlaFilterRef={hlaFilterRef} />
            <button
              type='submit'
              className={classNames('self-end', {
                'button-primary--active': !peptideLoading,
                'button-primary--disabled': peptideLoading,
              })}
            >
              Submit
            </button>
          </div>
        </form>

        {(peptideLoading || formSubmit) &&
          <ValidationResult searchType={type} searchTerm={term} hlaFilter={hlaFilterRef.current.value} />
        }

        <ResultTypeContext.Provider value={ResultType.OffTargetTableResult}>
          <ToOfftargetButton />
        </ResultTypeContext.Provider>
      </div>
    </Scrollbar>
  )
}

export default Validation
