export const snakeCaseToTitleCase = (str: string): string => {
  if (!str) return ''
  let result = ''
  let capitalizeNext = true
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '_') {
      result += ' '
      capitalizeNext = true
    } else {
      result += capitalizeNext ? str[i].toUpperCase() : str[i].toLowerCase()
      capitalizeNext = false
    }
  }

  result = result.replace(/hla/i, 'HLA')

  return result
}
