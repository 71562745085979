import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import App from './App'
import Offtarget from './pages/Offtarget'
import Validation from './pages/Validation'
import Route from './config/route'
import './index.css'
import '@aws-amplify/ui-react/styles.css'
import Samples from './pages/Samples'

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'peptide',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
      },
    ],
  },
})

const router = createBrowserRouter([
  {
    path: Route.Home,
    element: <App />,
    children: [
      {
        path: Route.Discover,
        element: <Validation />,
      },
      {
        path: Route.Offtarget,
        element: <Offtarget />,
      },
      {
        path: Route.Samples,
        element: <Samples />
      }
    ],
  },
])

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
