import useDialogStore from '../store/dialogStore'
import { useEffect } from 'react'
import isHotkey from 'is-hotkey'

const useDialog = () => {
  const { dialog, closeDialog } = useDialogStore()

  useEffect(() => {
    if (dialog) {
      const handleKeydown = event => {
        if (isHotkey('esc', event)) closeDialog()
      }

      window.addEventListener('keydown', handleKeydown)

      return () => window.removeEventListener('keydown', handleKeydown)
    }
  }, [ dialog, closeDialog ])

  return dialog
}

export default useDialog