import useDialogStore from '../store/dialogStore'
import { SampleInformationDialogType } from '../types/Dialog'
import { getSample } from '../client/apiClient'
import { useQuery } from '@tanstack/react-query'

const useSample = () => {
  const { dialog } = useDialogStore()
  const { sampleNumber } = (dialog as SampleInformationDialogType).payload

  return useQuery([ 'sample', sampleNumber ], () => getSample(sampleNumber))
}

export default useSample