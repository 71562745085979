import { Button } from 'src/@/components/ui/button'
import Sample from 'src/types/Sample'
import { ColumnDef, SortingFn } from '@tanstack/react-table'
import { ArrowUpDown } from 'lucide-react'
import { Link } from 'react-router-dom'
import Route from '../../../config/route'

const CustomHeaderButton: React.FC<{
  columnName: string
  column: any
  classNameProp: string
}> = ({ columnName, column, classNameProp }) => (
  <Button
    variant='ghost'
    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    className={`${classNameProp} cursor-pointer py-3 pl-4`}
  >
    <span className='text-left'>{columnName}</span>
    <ArrowUpDown className='ml-1 h-4 w-4' />
  </Button>
)

const sortColumnsFn: SortingFn<Sample> = (rowA, rowB, columnId) => {
  const propA = rowA.original[columnId]
  const propB = rowB.original[columnId]

  if (typeof propA === 'number' && typeof propB === 'number') {
    return propA - propB
  }

  return propA.localeCompare(propB)
}

const columnNames = [
  'protein_id',
  'gene_name',
  'protein_name',
]  

const columnMapping: Record<
  string,
  { headerText: string, classNameProp: string}
> = {
  protein_id: { headerText: 'Uniprot Identifier', classNameProp: 'w-48 shrink', },
  gene_name: { headerText: 'Gene Name', classNameProp: 'w-72 shrink', },
  protein_name: { headerText: 'Protein Name', classNameProp: 'w-[700px] shrink', },
}

export const generateColumns = (): ColumnDef<Sample>[] => {
  return columnNames.map((columnName) => ({
    accessorKey: columnName,
    header: ({ column }) => (
      <CustomHeaderButton
        columnName={columnMapping[columnName].headerText}
        column={column}
        classNameProp={columnMapping[columnName].classNameProp}
      />
    ),
    cell: ({ row }) => {
      const peptideProperty = row.original[columnName]
      switch (true) {
        case columnName === 'protein_id' || columnName === 'gene_name':
          return (
            <div className='link shrink whitespace-nowrap px-5 py-3'>
              <Link to={`${Route.Discover}/?searchType=protein&searchTerm=${peptideProperty}`} reloadDocument>{peptideProperty}</Link>
            </div>
          )
        default:
          return <div className='whitespace-nowrap px-5 py-3'>{peptideProperty}</div>
      }
    },
    sortingFn: sortColumnsFn,
  }))
}
