import { Image } from '@aws-amplify/ui-react'

const Header = () => {
  return (
    <div className='my-8 flex place-content-center'>
      <Image alt='Alithea Bio logo' src='/alithea-logo.svg' width='33%' className='mx-auto' />
    </div>
  )
}

export default Header