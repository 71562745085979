import { ReactElement } from 'react'

type Props = {
  children: ReactElement | string
}

const NoResultMessageBox = ({ children }: Props) => {
  return (
    <div className='w-full border border-amber-300 bg-amber-100 p-4'>
      {children}
    </div>
  )
}

export default NoResultMessageBox