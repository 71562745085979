import { INTENSITY_SCALA_SPECTRUM, MAX_INTENSITY_ON_SCALA } from '../config/config'

const intensityToHsl = (intensity: number) => {
  const normalizedIntensity = normalizeIntensity(intensity)
  const logarithmicIntensity = Math.log10(normalizedIntensity)

  const hue = INTENSITY_SCALA_SPECTRUM - (logarithmicIntensity * (INTENSITY_SCALA_SPECTRUM / maxValue))

  return `hsl(${hue}, 100%, 50%)`
}

const normalizeIntensity = (intensity: number): number => {
  if (intensity > MAX_INTENSITY_ON_SCALA) return MAX_INTENSITY_ON_SCALA
  if (intensity < 1) return 1

  return intensity
}

const maxValue = Math.log10(MAX_INTENSITY_ON_SCALA)

export default intensityToHsl