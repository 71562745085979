import { Link } from 'react-router-dom'
import Route from '../../config/route'
import React from 'react'
import useResultStore from '../../store/resultStore'
import ChevronIcon from '../icon/ChevronIcon'

const ToOfftargetButton = () => {
  const { result } = useResultStore()

  if (!result) return null

  return (
    <div className='mb-4 mt-6 border-t border-slate-100 pt-6'>
      <Link to={Route.Offtarget} className='button button-inline button-reset--active items-center space-x-1'>
        <ChevronIcon className='h-4 w-4 fill-none stroke-current stroke-2' />
        <span>Back to Toxicity Screening</span>
      </Link>
    </div>
  )
}

export default ToOfftargetButton