import { useQuery } from '@tanstack/react-query'
import { getSamples } from '../client/apiClient'
import { useMemo } from 'react'
import { SampleSorting, SortingDirection } from '../types/SortingDirection'
import useResultStore from '../store/resultStore'

const useSamples = () => {
  const { sortingDirection, sortBy } = useResultStore()
  const useQueryResult = useQuery([ 'samples' ], getSamples)

  const samples = useMemo(() => {
    if (!useQueryResult.data) return []

    const samples = useQueryResult.data

    return samples.sort((a, b) => {
      const sort = () => SampleSorting[sortBy](a, b)

      return (sortingDirection === SortingDirection.Descending) === sort() ? 1 : -1
    })

  }, [ sortBy, sortingDirection, useQueryResult.data ])

  return { ...useQueryResult, ...{ data: samples } }
}

export default useSamples