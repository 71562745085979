import useCategories from '../../../hooks/useCategories'
import classNames from 'classnames'
import { BACKGROUND_COLOR_MAPPING, BORDER_COLOR_MAPPING } from '../../../config/category'

const CategoryRow = () => {
  const categories = useCategories()

  return (
    <div className='flex w-full space-x-0.5 bg-slate-200 text-right text-xs'>
      {categories.map((category, index) => {
        const borderColor = BORDER_COLOR_MAPPING[category.type] ?? 'border-white'
        const backgroundColor = BACKGROUND_COLOR_MAPPING[category.type] ?? 'bg-white'

        return (
          <div className={classNames('w-6 shrink-0 overflow-hidden', backgroundColor, borderColor, { 'border-b-4': borderColor })} key={index}>
            <div className='flex h-64 origin-top-left translate-x-1 translate-y-full -rotate-90 pl-1 text-right'>
              <span className='whitespace-nowrap'>{category.name} ({category.samples})</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CategoryRow