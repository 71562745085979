import React from 'react'
import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../@/components/ui/table'
import { DataTablePagination } from '../pagination/DataTablePagination'
import { ScrollArea } from 'src/@/components/ui/scroll-area'
import { matchPeptide } from 'src/lib/proteinHelper'
import CsvDownloadButton from '../offtarget/CsvDownloadButton'
import Select from '../Select'
import Protein from 'src/types/Protein'

function generateOptionsFromUniprotIds(proteins: Protein[]) {
  const proteinIds: string[] = proteins.map((p) => p.protein_id)
  const options = proteinIds.map((v, index) => ({
    key: index,
    label: v,
    value: v
  }))

  return options
}

function getProteinSequence(proteins: Protein[], uniprotIdFilter: string): string {

  if (proteins === undefined) return ''

  return proteins.filter((el) => {
    return el.protein_id === uniprotIdFilter
  })[0].protein_seq
}

type PeptidePositionSpecialFilterTableProps = {
  columns: ColumnDef<Protein>[]
  proteins: Protein[]
  data: Protein[]
}

export function PeptidePositionSpecialFilterTable({ columns, proteins, data }: PeptidePositionSpecialFilterTableProps) {
  const [ uniprotIdFilter, setUniprotIdFilter ] = React.useState(proteins[0]?.protein_id)
  const [ proteinSequence, setProteinSequence ] = React.useState(proteins[0]?.protein_seq)
  const [ queryPeptide, setQueryPeptide ] = React.useState('')
  const [ from, setFrom ] = React.useState('')
  const [ to, setTo ] = React.useState('')

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const randomPostfix = crypto.randomUUID().substring(0, 8)
  const filename = `positional_info_${randomPostfix}.csv`
  
  function changeUniprotFilter(event: React.ChangeEvent<HTMLSelectElement>): void {
    const value = event.target.value
    table.getColumn('protein_id').setFilterValue(value)
    setProteinSequence(getProteinSequence(proteins, uniprotIdFilter))
    setUniprotIdFilter(value)
  }

  function changeFromFilter(value: string): void {
    table.getColumn('last_aa_position').setFilterValue(value)
    setFrom(value)
  }

  function changeToFilter(value: string): void {
    table.getColumn('first_aa_position').setFilterValue(value)
    setTo(value)
  }

  function changeQueryPeptide(proteinSequence: string, value: string): void {
    if (value !== '') {
      const startingPosition = matchPeptide(proteinSequence, value)
      const endPosition = startingPosition + value.length - 1
      changeFromFilter(startingPosition)
      changeToFilter(endPosition.toString())
    } else {
      changeFromFilter('')
      changeToFilter('')
    }
    setQueryPeptide(value)
  }

  return (
    <>
      <div className='mt-1 overflow-y-hidden rounded-md border-slate-200'>
        <div className='mb-4 flex items-center'>
          <span className='whitespace-nowrap pl-2 pr-3'>Uniprot ID:</span>
          <Select name='Uniprot ID' options={generateOptionsFromUniprotIds(proteins)} handleChange={changeUniprotFilter}></Select>
          <span className='whitespace-nowrap pr-3'>Query Peptide:</span>
          <input type='text' placeholder='Peptide' value={queryPeptide} onChange={(e) => changeQueryPeptide(proteinSequence, e.target.value)} className='ml-4 mr-2 h-12 rounded border p-1 text-gray-400 placeholder:text-gray-600 focus:text-black' />
          <span className='whitespace-nowrap pl-3'>Range:</span>
          <input type='text' placeholder='From' value={from} onChange={(e) => changeFromFilter(e.target.value)} className='ml-4 mr-2 h-12 w-36 rounded border p-1 text-gray-400 placeholder:text-gray-600 focus:text-black' />
          <input type='text' placeholder='To' value={to} onChange={(e) => changeToFilter(e.target.value)} className='ml-4 mr-2 h-12 w-36 rounded border p-1 text-gray-400 placeholder:text-gray-600 focus:text-black' />
          <div className='ml-auto'>
            <CsvDownloadButton data={data} filename={filename} />
          </div>
        </div>
        {/* <div className='mb-4 break-all'>{`Protein sequence for ${uniprotIdFilter}: ${proteinSequence}`}</div> */}
        <ScrollArea className='h-[550px] w-max drop-shadow'>
          <Table>
            <TableHeader className='sticky top-0 z-10 w-fit pr-4 text-sm font-medium leading-snug text-slate-50'>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className=' bg-sky-500 p-0 text-left text-white' style={{ paddingLeft: '0.2rem' }}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody className='cursor-pointer bg-white text-sm'>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'} className='border-b border-slate-200 last:border-b-0 hover:bg-slate-100'>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className='truncate p-0 text-left' style={{ paddingLeft: '0.2rem' }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className='p-0 text-center'>
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
      <DataTablePagination table={table} />
    </>
  )
}
