import { CSVLink } from 'react-csv'

type Props = {
  data: any[] // Array of objects with similar attibutes
  filename: string
  label?: string
}

const CsvDownloadButton = ({ data, filename, label = 'Download data as CSV' }: Props) => {

  if (!data || data.length === 0) return null

  return <CSVLink className='button button-inline button-primary--active' data={data} filename={filename}>{label}</CSVLink>
}

export default CsvDownloadButton