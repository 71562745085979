import { ReactElement } from 'react'
import Message from '../types/Message'
import { v4 as uuidv4 } from 'uuid'
import { DEFAULT_FLASH_MESSAGE_DURATION } from '../config/config'
import { toast, TypeOptions } from 'react-toastify'
import FlashMessage from '../components/messages/FlashMessage'

const create = (heading: string, text?: ReactElement | string): Message => {
  const id = uuidv4()

  return { id, heading, text }
}

const addFlashMessage = (heading: string, text?: ReactElement | string, type: TypeOptions = 'success', duration?: number): void => {
  const message = create(heading, text)
  const toastContent = <FlashMessage message={message} />
  const autoClose = [ 'error', 'warning' ].includes(type) ? false : duration || DEFAULT_FLASH_MESSAGE_DURATION
  const toastId = message.id

  toast(toastContent, { toastId, type, autoClose })
}

export default addFlashMessage