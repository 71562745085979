import HeatmapItem from '../../../types/HeatmapItem'
import { Tooltip } from 'react-tooltip'
import { Fragment } from 'react'

type Props = {
  heatmapItems: HeatmapItem[]
}

const SampleCounts = ({ heatmapItems }: Props) => {
  return (
    <div className='space-y-0.5 bg-slate-200 pl-1.5 text-xs'>
      {heatmapItems.map(({ peptide, count }) => {
        const tooltipId = `count-${peptide}.${count.items}.${count.samples}`

        return (
          <Fragment key={`count-${peptide}`} >
            <div className='flex h-8 cursor-help items-center gap-x-1 bg-white py-1 text-center' data-tooltip-id={tooltipId}>
              <div className='w-6'>{count.items}</div>
              <div className='w-6'>{count.samples}</div>
            </div>

            <Tooltip id={tooltipId} place='left'>
              <div className='flex space-x-4 text-sm'>
                <div className='font-medium'>
                  Peptide: <br />
                  Positive Categories:<br />
                  Positive Samples:<br />
                  Aggregated Intensity:
                </div>
                <div>
                  {peptide}<br />
                  {count.items}<br />
                  {count.samples}<br />
                  {Intl.NumberFormat(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(count.intensity)}
                </div>
              </div>
            </Tooltip>
          </Fragment>
        )
      })}
      <div className='box-content flex h-64 shrink-0 border-b-4 border-white bg-white'>
        <div className='w-6 shrink-0 overflow-hidden'>
          <div className='flex h-full origin-top-left translate-x-1 translate-y-full -rotate-90 pl-1 text-right'>
            <span className='whitespace-nowrap'>Positive Categories</span>
          </div>
        </div>
        <div className='w-6 shrink-0 overflow-hidden'>
          <div className='flex h-full origin-top-left translate-x-1 translate-y-full -rotate-90 pl-1 text-right'>
            <span className='whitespace-nowrap'>Positive Samples</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SampleCounts