import getBackgroundGradient from '../../lib/getBackgroundGradient'
import { LEGEND_LABELS } from '../../config/config'
import { BACKGROUND_COLOR_MAPPING, BORDER_COLOR_MAPPING } from '../../config/category'
import classNames from 'classnames'

const Legend = () => {
  return (
    <>
      <div className='w-full pt-8'>
        <h4>Mean Normalized Intensity</h4>
        <div className='my-4 h-10 w-full' style={{ background: `linear-gradient(${getBackgroundGradient()})` }} />
        <div className='flex justify-between'>
          {LEGEND_LABELS.map((value, index) => <span key={value}>{Intl.NumberFormat().format(value)}{index === LEGEND_LABELS.length - 1 && '+'}</span>)}
        </div>
      </div>
      <div className='space-y-2 pt-8 text-sm'>
        <h4>Category Types</h4>
        {Object.entries(BORDER_COLOR_MAPPING).map(([ categoryType, borderColor ]) => (
          <div className={classNames('border-l-4 flex py-2 items-center pl-2', borderColor, BACKGROUND_COLOR_MAPPING[categoryType])} key={categoryType}>
            <span className='first-letter:uppercase'>{categoryType}</span>
          </div>
        ))}
      </div>
    </>
  )
}

export default Legend