import { CategoryType } from '../types/Category'

export const BORDER_COLOR_MAPPING: Record<CategoryType, string> = {
  [CategoryType.Plasma]: 'border-red-500',
  [CategoryType.Tissue]: 'border-blue-500',
  [CategoryType.PrimaryCells]: 'border-amber-500',
}

export const BACKGROUND_COLOR_MAPPING: Record<CategoryType, string> = {
  [CategoryType.Plasma]: 'bg-red-50',
  [CategoryType.Tissue]: 'bg-blue-50',
  [CategoryType.PrimaryCells]: 'bg-amber-50',
}